<template lang="pug">
.brand-wrapper.bg-light
  .container
    .row(v-if="loadingPlans" style="height: calc(100vh - 8rem)")
      loading-logo
    .row(v-else)
      .col-12.col-md-8
        .customer-data.d-flex.flex-column
          .customer-data-inner.d-flex.justify-content-start.align-items-center.flex-column
            .header-text.order-summary.justify-content-between
              .d-flex.flex-wrap.align-items-center
                order-summary-svg.header-text-icon
                span {{ $t('orderSummary') }}
            .w-100
              .d-flex.customer-data-content
                .row.col-8.p-0
                  .col-12.mb-6
                    .row.order-summary-box.mb-3
                      .col-5
                        .order-summary-box-label {{ $t('selectedPlan') }}
                      .col-7
                        .order-summary-box-data-title {{ getPlanNameToShow }}
                        .order-summary-box-data-desc {{ getPeriod && getPlan ? planOriginalPrice : '' }} / {{ $t(selectedPeriodLanguageKey) }}
                        .order-summary-box-data-desc(v-if="hasUpgrade") ({{ `${$t('billedNextOn')} ${formattedNextBillingDate}` }})

                    .row.order-summary-box.mb-3(v-if="hasUpgrade")
                      .col-5
                        .order-summary-box-label {{ $t('upgradePrice') }}
                      .col-7
                        .order-summary-box-data-title {{ formattedUpgradePrice }}

                    .row.order-summary-box.mb-3(v-if="hasValidCouponDiscountForSelectedPlan")
                      .col-5
                        .order-summary-box-label {{ $t('couponDetails.couponItemName') }}
                      .col-7
                        .order-summary-box-data-title {{ formattedDiscountString }}

                    .row.order-summary-box.mb-6
                      .col-5
                        .order-summary-box-label {{ $t('total') }}
                      .col-7
                        .order-summary-box-data-title {{ formattedTotal }}

                    .row.order-summary-box.pt-3.mb-6(v-if="!isPaying")
                      .col-12
                        .d-flex.align-items-center
                          input#checkout-terms(
                            type="checkbox"
                            v-model="terms"
                            name="checkbox"
                            value=""
                          )
                          label.mb-0.ml-2(
                            for="checkout-terms"
                            v-html="$t('termsAndConditions', { link: $t('termsAndConditionsLink') })"
                          )
                            a.ml-1.font-weight-bold.customer-data-policy(href="#") Terms

                    .row.order-summary-box(v-if="!isPaying && invalidTerms")
                      .col-12
                        .order-summary-box.order-summary-box-info.alert.alert-primary
                          .col-2.col-md-3.col-lg-2.p-0.mr-1.d-flex.justify-content-center
                            .fa.fa-exclamation-triangle
                          .col-10.col-md-9.col-lg-10.p-0
                            div {{ $t('errorTermsAndConditions') }}

                    .row.order-summary-box.pb-3
                      .col-12(style="font-size: 1.125rem; font-weight: 700")
                        div {{ $t('infoAboutConfirmShopifyOrder.line1') }}
                        div {{ $t('infoAboutConfirmShopifyOrder.line2') }}

                    .row.order-summary-box
                      .col-12.d-flex.flex-column.bg-white
                        .d-flex.flex-row.justify-content-start.align-items-center
                          .mr-3.pt-1
                            om-button(
                              primary
                              :loading="orderInProgress"
                              @click.prevent="confirmOrder"
                            ) {{ $t('buttonConfirmShopifyOrder') }}

      .col-12.col-md-4
        .plan-information.d-flex.flex-column.bg-white
          .plan.plan-information-title.d-flex.flex-row.justify-content-between.align-items-center
            .select-plan.plan-header {{ $t('selectedPlan') }}
            router-link(
              :to="{ name: 'plan_settings', params: { userId: getAccountIdFromCookie() } }"
            )
              .plan.plan-modify {{ $t('modify') }}
          .plan-information-box
            .plan.plan-type {{ getPlanNameToShow }}
            .plan.plan-price.mb-1 {{ planOriginalPrice }} / {{ $t(selectedPeriodLanguageKey) }}
            .plan.plan-benefit(
              v-if="isFreemiumPackage"
              v-html="$t('freemiumPlanDescription', { maxSites: getPlanDetail(getPlan).maxDomains, noOfPageViews: addThousandSep(getPlanDetail(getPlan).maxVisitors) })"
            )

        .plan-information.d-flex.flex-column.bg-white.mt-5(
          v-if="isEnabledCouponActivation && !getCouponCode"
        )
          .plan.plan-information-title
            .plan-header {{ $t('couponDetails.couponInputBoxTitle') }}
          .plan-information-box
            .row.plan-item-row
              .col-12.pb-1
                span.brand-under-heading-text {{ $t('couponDetails.couponInputLabel') }}
            .row.plan-item-row
              .col-8
                om-input#coupon-activation(
                  type="text"
                  v-model="couponInput"
                  name="coupon"
                  :disabled="couponActivationInProgress || orderInProgress"
                  value=""
                )
              .col-4.text-right
                om-button(
                  secondary
                  :loading="couponActivationInProgress"
                  :disabled="orderInProgress"
                  @click.prevent="activateCoupon"
                ) {{ $t('couponDetails.activationButton') }}
            .row.plan-item-row.mt-3(v-if="getActivationProblemNotificationType")
              .col-12(style="color: #b20000") {{ $t(`couponDetails.alert.failedActivation.${getActivationProblemNotificationType}`) }}

        .plan-information.d-flex.flex-column.bg-white.mt-5(v-if="getCouponCode")
          .plan.plan-information-title
            .row
              .plan-header.col-8 {{ $t('couponDetails.activatedCouponTitle') }}
              .col-4.pt-1.text-right
                .plan.plan-remove.cursor-pointer(
                  v-if="couponService.hasStartedCouponActivation()"
                  @click.prevent="resetStartedCouponActivation()"
                ) {{ $t('couponDetails.cancelCoupon') }}
          .plan-information-box.pb-5
            div(
              :class="hasValidCouponDiscountForSelectedPlan ? 'plan-green-box' : 'plan-gray-box'"
            )
              .row.plan-item-row
                .col-12.plan-item-col-bold {{ getCouponDescription }}
              .row.plan-item-row.mt-3(v-if="getCouponValidityPeriod")
                .col-12.d-flex.flex-column
                  .d-flex.flex-wrap.justify-content-between
                    .pr-3 {{ $t('couponDetails.validityPeriod') }}:
                    div {{ getCouponValidityPeriod.start }} - {{ getCouponValidityPeriod.end }}
            .row.plan-item-row.mt-3(v-if="hasValidCouponDiscountForSelectedPlan === false")
              .col-12(style="color: #b20000") {{ $t('couponDetails.alert.invalidForPlan') }}
</template>
<script>
  import moment from 'moment';
  import GET_SALES_AUTH from '@/graphql/GetSalesAuth.gql';
  import { createNamespacedHelpers, mapGetters } from 'vuex';
  import { getAccountIdFromCookie, separatePrice, pushDataLayer } from '@/util';
  import { isFreemiumPackage } from '@om/payment/src/helpers/planDetails';
  import { PaymentAdapter } from '@/services/paymentAdapter';
  import { track } from '@/services/xray';

  import {
    calculateUpgradePrice,
    calculateRefund,
  } from '@om/payment/src/helpers/upgradeCalculator';

  import dateFormat from '@/mixins/dateFormat';

  const {
    mapGetters: paymentGetters,
    mapState: paymentState,
    mapActions: paymentActions,
  } = createNamespacedHelpers('payment');

  export default {
    mixins: [dateFormat],
    data() {
      return {
        PaymentAdapter: null,
        terms: false,
        invalidTerms: false,

        plan: '',
        period: 1,
        couponInput: '',
        couponActivationInProgress: false,
        loadingPlans: true,
        orderInProgress: false,
      };
    },

    computed: {
      ...mapGetters([
        'accountFeatures',
        'hasActiveSubscription',
        'isPaying',
        'currentPlan',
        'currentPeriod',
      ]),
      ...paymentState(['dateExpires', 'couponService']),
      ...paymentGetters([
        'isShopifyPayment',
        'getPlainPlanName',
        'getPlans',
        'getPlanDetail',
        'hasFuturePackage',
      ]),

      getPlan() {
        return this.plan;
      },
      getPeriod() {
        return this.period;
      },
      hasUpgrade() {
        return this.hasActiveSubscription;
      },
      isFreemiumPackage() {
        return isFreemiumPackage(this.getPlan);
      },
      selectedPeriodKey() {
        return this.periodKeyByNumberOfMonths(this.getPeriod);
      },
      selectedPeriodLanguageKey() {
        let period = 'month';
        if (this.getPeriod === 12) {
          period = 'annual';
        } else if (this.getPeriod === 3) {
          period = 'quarter';
        }
        return period;
      },
      getPlanNameToShow() {
        return this.getPlainPlanName(this.getPlan.toUpperCase());
      },
      planPrice() {
        return this.formatPrice(this.getPlanPrice());
      },
      planOriginalPrice() {
        return this.formatPrice(this.getPlanOriginalPrice());
      },

      calculateDiscount() {
        if (!this.hasValidCouponDiscountForSelectedPlan) return 0;

        const { priceNumber: originalPrice } = this.getPlanOriginalPrice();

        if (this.hasUpgrade && this.upgradePrice) {
          const currency = this.getRegion === 'Hungary' ? 'HUF' : 'USD';
          const toPlanDetails = this.getPlanDetail(this.getPlan);
          return this.couponService.calculateDiscountForUpgradePrice(
            toPlanDetails.name,
            this.getPeriod,
            originalPrice,
            this.upgradePrice,
            currency,
          );
        }
        const { priceNumber: discountedPrice } = this.getPlanPrice();

        return originalPrice - discountedPrice;
      },
      calculateTotal() {
        let { priceNumber: planPrice } = this.getPlanOriginalPrice();
        if (this.hasUpgrade && this.upgradePrice) {
          planPrice = this.upgradePrice;
        }
        const discount = this.calculateDiscount;
        const sum = planPrice - discount;

        return sum;
      },
      upgradePrice() {
        const toPlanDetails = this.getPlanDetail(this.getPlan);
        const toPeriod = this.getPeriod;
        const currentPlanDetails = this.getPlanDetail(this.currentPlan);
        const currentPeriod = this.currentPeriod;

        const toPlanPrice = this.getOriginalPlanPriceInHuf(toPlanDetails, toPeriod);
        const currentPlanPrice = this.getOriginalPlanPriceInHuf(currentPlanDetails, currentPeriod);

        let upgradePrice;

        if (this.isMonthlyToAnnualCase(currentPeriod, toPeriod)) {
          const refund = calculateRefund({
            dateExpires: this.dateExpires,
            packagePrice: currentPlanPrice,
            period: currentPeriod,
            isShopify: true,
          });
          upgradePrice = refund < toPlanPrice ? toPlanPrice - refund : 0;
        } else {
          upgradePrice = calculateUpgradePrice({
            dateExpires: this.dateExpires,
            period: this.period,
            currentPackagePrice: currentPlanPrice,
            newPackagePrice: toPlanPrice,
            isShopify: true,
          });
        }

        if (this.getRegion !== 'Hungary') {
          upgradePrice /= 250;
        }

        return upgradePrice;
      },
      formattedTotal() {
        const { prefix, suffix } = this.getPlanPrice();
        const sum = this.calculateTotal;
        const roundedPrice = this.getRegion === 'Hungary' ? Math.round(sum) : sum.toFixed(2);
        return `${prefix} ${this.$options.filters.thousandSep(roundedPrice, ' ')} ${suffix}`;
      },
      formattedUpgradePrice() {
        const { prefix, suffix } = this.getPlanPrice();
        return this.formatPrice(
          { prefix, priceNumber: this.upgradePrice, suffix },
          this.getRegion !== 'Hungary',
        );
      },
      formattedNextBillingDate() {
        const currentPeriod = this.currentPeriod;
        const toPeriod = this.getPeriod;
        if (this.isMonthlyToAnnualCase(currentPeriod, toPeriod)) {
          return this.formatDate(moment.utc().add(toPeriod, 'months').toDate());
        }
        return this.formatDate(moment.utc(this.dateExpires).subtract(14, 'days').toDate());
      },
      formattedDiscountString() {
        const { prefix, suffix } = this.getPlanPrice();
        const vat = this.calculateDiscount;
        const roundedPrice =
          this.getRegion === 'Hungary' ? Math.round(vat) : parseFloat(vat.toFixed(2));
        return `- ${this.formatPrice({ prefix, priceNumber: roundedPrice, suffix })}`;
      },

      isEnabledCouponActivation() {
        if (this.hasFuturePackage) return false;
        return this.couponService.isEnabledCouponActivation();
      },
      getCouponCode() {
        if (this.hasFuturePackage) return false;
        if (this.couponService.hasValidCoupon() === false) return null;
        const code = this.couponService.getCouponCode();
        return code;
      },
      getCouponCodeWithPlanValidation() {
        if (this.hasFuturePackage) return null;
        if (this.hasValidCouponDiscountForSelectedPlan === false) return null;
        return this.getCouponCode;
      },
      getCouponDescription() {
        const languageKey = this.$i18n.locale === 'hu' ? 'hu' : 'en';
        return this.couponService.getCouponDescription(languageKey);
      },
      getCouponValidityPeriod() {
        const dates = this.couponService.getCouponValidityPeriod();
        if (!dates) return '';
        const start = this.formatDate(dates.start);
        const end = this.formatDate(dates.end);
        return { start, end };
      },
      hasValidCouponDiscountForCurrentPlan() {
        if (this.couponService.hasValidCoupon() === false) return false;
        const planDetails = this.getPlanDetail(this.currentPlan);
        return this.hasDiscountForPlan(planDetails, this.currentPeriod);
      },
      hasValidCouponDiscountForSelectedPlan() {
        if (this.couponService.hasValidCoupon() === false) return false;
        const planDetails = this.getPlanDetail(this.getPlan);
        return this.hasDiscountForPlan(planDetails, this.getPeriod);
      },
      getActivationProblemNotificationType() {
        const type = this.couponService.getNotificationType();
        if (!type || type.match(/^activation.*$/) === null) return null;
        return type;
      },
    },

    async created() {
      if (!this.isShopifyPayment) {
        this.goToPlanSettings({ checkoutProblem: 'shopify' });
      }

      const packageParam = this.$route.query.package;

      if (packageParam) {
        const tmp = packageParam.split('-');
        this.plan = tmp[0];
        this.period = parseInt(tmp[1] || 1, 10);
      } else {
        this.goToPlanSettings({ checkoutProblem: 'param' });
      }
    },

    async mounted() {
      await this.loadPlans();
      this.loadingPlans = false;

      const planDetails = this.getPlanDetail(this.getPlan);
      if (!planDetails?.price[this.selectedPeriodKey]) {
        this.goToPlanSettings({ checkoutProblem: 'plan' });
      }

      const total = this.calculateTotal;
      if (!total || total < 0) {
        this.goToPlanSettings({ checkoutProblem: 'total' });
      }

      const {
        data: { salesAuth },
      } = await this.$apollo.query({
        query: GET_SALES_AUTH,
      });

      this.$set(
        this,
        'PaymentAdapter',
        new PaymentAdapter(salesAuth, this.accountFeatures, this.$apollo),
      );
    },

    methods: {
      ...paymentActions(['loadPlans']),

      async confirmOrder() {
        if (!this.terms && !this.isPaying) {
          this.invalidTerms = true;
          return;
        }

        try {
          await this.sendShopifyOrder();
        } catch (err) {
          this.orderInProgress = false;
          this.$notify({
            type: 'error',
            text: this.$t('notifications.sendError'),
          });
        }
      },

      async sendShopifyOrder(type = 'recurring', location = 'shopify_checkout') {
        this.orderInProgress = true;

        const plan = this.getPlan;
        const period = this.getPeriod;
        const couponCode = this.getCouponCodeWithPlanValidation;

        track('admin_click', {
          location,
          setting: type,
          change: 'shopify-plan',
        });

        const { data } = await this.PaymentAdapter.sendShopifyPay(plan, period, couponCode, type);

        if (data && data.success) {
          pushDataLayer({
            event: 'select_item',
            item_id: plan,
            item_name: 'plan_upgrade',
          });
          window.location = data.confirmUrl || data.confirmUri;
        } else {
          this.$notify({
            type: 'error',
            text: data.message || data.error,
          });

          this.orderInProgress = false;
        }
      },

      goToPlanSettings(query) {
        if (query.checkoutProblem) {
          this.$notify({
            type: 'error',
            text: 'Something went wrong!',
            duration: 15000,
          });
        }
        this.$router.replace({
          name: 'plan_settings',
          params: { userId: getAccountIdFromCookie() },
          query,
        });
      },

      getAccountIdFromCookie() {
        return getAccountIdFromCookie();
      },

      periodKeyByNumberOfMonths(periodInMonth) {
        let period = 'monthly';
        if (periodInMonth === 12) {
          period = 'annual';
        } else if (periodInMonth === 3) {
          period = 'quarterly';
        }
        return period;
      },

      addThousandSep(value) {
        return this.$options.filters.thousandSep(value);
      },

      formatPrice({ prefix, priceNumber, suffix }, fixed = false) {
        if (fixed) priceNumber = priceNumber?.toFixed(this.fractionDigits);
        return `${prefix} ${this.$options.filters.thousandSep(priceNumber, ' ')} ${suffix}`;
      },

      getPlanPrice(planDetails) {
        if (!planDetails) planDetails = this.getPlanDetail(this.getPlan);
        return separatePrice(
          this.getPeriod && this.getPlan ? planDetails.price[this.selectedPeriodKey] : '',
        );
      },

      getPlanOriginalPrice(planDetails) {
        if (!planDetails) planDetails = this.getPlanDetail(this.getPlan);
        if (!planDetails?.originalPrice) {
          return this.getPlanPrice(planDetails);
        }
        return separatePrice(
          this.getPeriod && this.getPlan ? planDetails.originalPrice[this.selectedPeriodKey] : '',
        );
      },

      getPlanPriceInHUF(planDetails, periodInMonth) {
        if (!planDetails) planDetails = this.getPlanDetail(this.getPlan);
        const periodKey = this.periodKeyByNumberOfMonths(periodInMonth);
        let { priceNumber } = separatePrice(planDetails.price[periodKey]);
        if (this.getRegion !== 'Hungary') {
          priceNumber *= 250;
        }

        return priceNumber;
      },

      getOriginalPlanPriceInHuf(planDetails, periodInMonth) {
        if (!planDetails) planDetails = this.getPlanDetail(this.getPlan);
        const periodKey = this.periodKeyByNumberOfMonths(periodInMonth);
        const priceString = planDetails.originalPrice[periodKey];
        let { priceNumber } = separatePrice(priceString);
        if (this.getRegion !== 'Hungary') {
          priceNumber *= 250;
        }

        return priceNumber;
      },

      hasDiscountForPlan(plan, periodInMonth) {
        const periodKey = this.periodKeyByNumberOfMonths(periodInMonth);
        return plan.hasDiscount && plan.hasDiscount[periodKey];
      },

      isMonthlyToAnnualCase(fromPeriod, toPeriod) {
        if (fromPeriod === 1 && toPeriod === 12) {
          return true;
        }

        return false;
      },

      async activateCoupon() {
        this.couponActivationInProgress = true;
        const isSuccess = await this.couponService.activateCoupon(this.couponInput);
        if (isSuccess) {
          this.$router.replace({
            name: 'shopify_checkout',
            query: {
              package: `${this.getPlan}-${this.getPeriod}`,
              activatedCoupon: this.couponInput,
            },
          });
        }
        this.couponActivationInProgress = false;
      },

      resetStartedCouponActivation() {
        this.couponService.resetStartedCouponActivation();
        this.$router.replace({
          name: 'shopify_checkout',
          params: { step: this.$route.params.step },
          query: { package: `${this.getPlan}-${this.getPeriod}`, deactivatedCoupon: true },
        });
      },
    },
  };
</script>
